<template>
  <div class="ss-hero">
    <div class="ss-callout">
      <h1>
        <span class="ss-highlight">Hello</span> <br />
        I'm Steve<span class="ss-highlight">.</span>
      </h1>
      <p>I build things with <a href="https://github.com/zeroedin">code</a>.</p>
      <p>I ski, hike, scuba dive, and love to travel.</p>
      <p>I have an amazing <a href="https://octaviaspriggs.com">wife</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Homepage",
};
</script>
