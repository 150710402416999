import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faGithub,
  faLinkedin,
  faInstagram,
  faFlickr,
  faMastodon,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import "./assets/scss/styles.scss";

library.add(
  faHome,
  faGithub,
  faLinkedin,
  faInstagram,
  faFlickr,
  faMastodon,
  faYoutube
);

createApp(App)
  .use(store)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
