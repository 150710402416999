import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/resume",
    name: "resume",
    // route level code-splitting
    // this generates a separate chunk (resume.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Resume.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contact.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
