<template>
  <a href="#main" class="skip-to-content">Skip to Content</a>
  <div class="ss-nav">
    <router-link :to="{ name: 'home', params: {} }">
      <font-awesome-icon icon="home" class="fa-2x" />
      <span class="sr-only">Home</span>
    </router-link>
    <router-link :to="{ name: 'resume', params: {} }">Résumé</router-link>
    <router-link :to="{ name: 'contact', params: {} }">Contact</router-link>
  </div>
  <main id="main">
    <router-view />
  </main>
</template>

<script>
export default {
  name: "App",
};
</script>
